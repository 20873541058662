import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import CNUWhiteLogo from "../../images/cnu-logo_white.png";
import CNUBlackLogo from "../../images/cnu-logo.png";

import "./navbar.scss";

const Navbar = () => {
  useEffect(() => {
    setOnTableOrMobileSize(window.innerWidth <= 900);
  });

  const [onTableOrMobileSize, setOnTableOrMobileSize] = useState(false);
  const logoToUse = onTableOrMobileSize ? CNUBlackLogo : CNUWhiteLogo;
  const [showMenu, setShowMenu] = useState(false);
  const showNavbarOptions = () => {
    if (!onTableOrMobileSize) return true;
    if (showMenu) return true;
    return false;
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="navbar">
      <div className="branding-row">
        <Link className="navbar__logo" to="/">
          <img src={logoToUse} alt="creatives near you logo" />
        </Link>
        <button className="navbar__mobile-menu-button" onClick={toggleMenu}>
          menu
        </button>
      </div>
      <div className={showMenu && "navbar__overlay"} />
      <ul className={`${showNavbarOptions() ? "navbar__links " : "hide"}`}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCQfHRb0niT8c-zst8jr5lZQ"
          >
            Media
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
