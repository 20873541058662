import React from "react"

import "./icon.scss"

const Icon = ({ iconComponent: IconComponent, backgroundColor }) => {
  return (
    <div style={{ background: backgroundColor }} className="icon-wrapper">
      {IconComponent}
    </div>
  )
}

export default Icon
