
import React from 'react'
import Navbar from './navbar/navbar'

import './layout.scss'
import Footer from './footer/footer'

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className='wrapper'>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
