import React from "react";
import Icon from "../icon/icon";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaMailBulk,
  FaMailchimp,
  FaVoicemail,
  FaInbox,
} from "react-icons/fa";

import "./footer.scss";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__quick-links">
        <h2 className="quick-links-header">Home</h2>
        <Link to="/story">Our Story</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className="footer__social">
        <h2>Contact Us</h2>
        <a href="mailto: cnu@creativesnearyou.com">cnu@creativesnearyou.com </a>
        <div className="links">
          <a href="https://www.facebook.com/creativesnearu/" target="_blank">
            <Icon backgroundColor="#3b5998" iconComponent={<FaFacebookF />} />
          </a>
          <a href="https://twitter.com/creativesnearu" target="_blank">
            <Icon backgroundColor="#1da1f2" iconComponent={<FaTwitter />} />
          </a>

          <a href="https://www.instagram.com/creativesnearu/" target="_blank">
            <Icon backgroundColor="#c13584" iconComponent={<FaInstagram />} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
